@toolbar-height: 40px;

.widget-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: white;
  border: 1px solid @border-color-base;
  padding: 0 5px;
  height: @toolbar-height;
  border-radius: @border-radius-base @border-radius-base 0 0;

  &__section {
    flex: 1;
    position: relative;
    z-index: 2;
    min-width: 0px;
    display: inline-flex;
    align-items: center;

    &.left {
      text-align: left;
      margin-right: auto;
      justify-content: flex-start;
    }

    &.center {
      flex: 1;
      z-index: 1;
      text-align: center;
      justify-content: center;
    }

    &.right {
      text-align: right;
      margin-left: auto;
      justify-content: flex-end;
    }
  }

  .ant-divider {
    height: @toolbar-height;
    background-color: @border-color-base; 
    top: 0;
  }
}