@import '~antd/dist/antd.less';
@import 'theme.less';
@import '_ColoredCard.less';
@import '_Divider.less';
@import '_Download.less';
@import '_IconBadge.less';
@import '_Forms.less';
@import '_Footer.less';
@import '_Home.less';
@import '_WidgetToolbar.less';
@import '_MapLayerSummary.less';
@import '_NavBar.less';
@import '_Tooltip.less';
@import '_Observation.less';
@import '_Offline.less';
@import '_OverlaySpinner.less';
@import '_Collapse.less';
@import '_Select.less';
@import '_Table.less';
@import '_Visualization.less';

.ant-btn > svg[data-prefix] + span,
.ant-btn > span + svg[data-prefix] {
  margin-left: 8px;
}

.ant-statistic-content {
  color: @statistic-content-color;
}

.ant-typography {

  &.ant-typography-light {
    font-weight: 300;
  }

  &.ant-typography-primary {
    color: @primary-color;
  }

  &.ant-typography-center {
    text-align: center;
  }
}

h5.ant-typography, .ant-typography h5 {
  font-size: 16px;
}

@media (max-width: @screen-md) {
  h1.ant-typography, .ant-typography h1 {
    font-size: @heading-1-size-mobile;
  }

  h2.ant-typography, .ant-typography h2 {
    font-size: @heading-2-size-mobile;
  }

  h3.ant-typography, .ant-typography h3 {
    font-size: @heading-3-size-mobile;
  }

  h4.ant-typography, .ant-typography h4 {
    font-size: @heading-4-size-mobile;
  }

  h5.ant-typography, .ant-typography h5 {
    font-size: @heading-5-size-mobile;
  }
}

.ant-modal {
  padding-bottom: 100px;
}

.container {
  max-width: 1200px;
  margin: auto;
  position: relative;

  @media (max-width: 1240px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  &.small {
    max-width: 500px;
  }
}

.mapboxgl-popup-close-button {
  font-size: 20px;
}

@media (max-width: @screen-lg) {
  .layout-section {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
}

iframe#launcher-frame {
  max-width: 105px !important;
}

.mapboxgl-ctrl-group + .mapboxgl-ctrl-group {
  margin-top: 1rem;
}

@primary-color: #5A7201;@success-color: #5A7201;@info-color: #5A7201;@processing-color: #5A7201;@screen-lg: 1067px;