.home-hero {
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(247, 246, 252) 100%);
  border-radius: @border-radius-base;
  position: relative;

  @media (max-width: @screen-lg) {
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(247, 246, 252) 100%);
    flex-direction: column-reverse;
    align-items: initial;
    text-align: center;
    max-width: 630px;
    margin: auto;
    border: 1px solid @border-color-base;
  }

  &__card {
    margin-top: -2em;

    @media (max-width: @screen-lg) {
      margin-top: 10px;
    }
  }

  &__image {
    background-color: white;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 2px 20px 0px @shadow-color;
    border-radius: @border-radius-base;
    height: 400px;
    margin: -20px 0 -20px -20px;

    @media (max-width: @screen-lg) {
      height: 300px;
      margin: -20px;
    }
  }

  &__link {
    display: inline-block;
    position: relative;

    .ant-typography {
      margin: 0;
      position: relative;
      
      span {
        position: relative;
        z-index: 1;
      }
    }

    &--icon {
      position: absolute;
      left: calc(100% + 10px);
      top: 50%;
      transform: translate(0, -50%);
      transition: .2s ease-in-out transform;
    }

    &--underline {
      opacity: .2;
      position: absolute;
      bottom: 5px;
      left: 0;
      z-index: 0;
      filter: brightness(2);
    }

    em {
      font-weight: 600;
      position: relative;
    }

    &:hover {
      .home-hero__link--icon {
        transform: translate(5px, -50%);
      }
    }
  }

  &__message {
    padding: 40px;

    > .ant-typography {
      font-weight: 300;
      
      @media (min-width: @screen-md) {
        font-size: 40px;
      }
    }
  }
}

.home-stats {
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    max-width: 630px;
    margin: -2em auto 0 !important;

    .colored-card__title {
      margin-bottom: 0;
    }
  }
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  text-align: left;
}

.home-about {

  .ant-tabs-tab {
    text-align: left;
  }

  &__tabs {
    display: flex;

    .ant-tabs-content {
      display: flex;
    }

    .tabs-left-content {
      flex: 1;
    }

    .ant-tabs-bar {
      width: 350px;
      flex: none;

      @media (max-width: @screen-lg)  {
        display: none;
      }

      .tab-icon {
        opacity: 0;
        user-select: none;
        pointer-events: none;
        font-size: 17px;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 100%;
        margin-right: 10px;
      }

      .ant-tabs-tab {
        text-align: left;
        white-space: normal;

        h3 {
          position: relative;
        }
      }

      .ant-tabs-tab-active {

        .tab-icon {
          opacity: 1;
          transition: .2s ease-in-out opacity;
        }

        h3 {
          color: @primary-color;
        }
      }
    }
    @media (max-width: @screen-lg)  {
      .ant-tabs-left-content {
        padding: 0;
        border: none;
      }
    }

    .ant-tabs-tabpane {
      padding: 20px;
      background: linear-gradient(-180deg, rgb(255, 255, 255) 0%, rgb(248, 247, 252) 100%);
      border-radius: @border-radius-base;
      border: 1px solid @border-color-base;
      box-shadow: 0px 0px 5px 0px @shadow-color;
      margin: 5px;
      position: relative;

      // TEMPORARY STYLES START
      div[style*="display:flex"] {
        margin-bottom: 20px;
      }

      img {
        align-self: center;
        margin: 10px;
      }
      // TEMPORARY STYLES END

      @media (min-width: @screen-lg)  {
        &-inactive {
          margin: 0;
          border: none;
        }
      }

      @media (max-width: @screen-lg)  {
        padding: 20px !important;
        top: 0 !important;
        left: 0 !important;
        margin-bottom: 10px !important;
        visibility: visible !important;
        height: auto !important;
        opacity: 1 !important;
        overflow: visible !important;
        opacity: 1 !important;
        pointer-events: initial !important;
        border: 1px solid @border-color-base !important;

      }
      
      // TEMPORARY STYLES START
      @media (max-width: @screen-md) {
        div[style*="display:flex"] {
          flex-direction: column;
        }
        img {
          align-self: flex-start !important;
          margin: 20px 0 !important;
        }
      }
      // TEMPORARY STYLES END
    }
  }
}

.home-observations {
  background-blend-mode: overlay;
  background-image: url(../assets/images/home-observations-bg.png);
  background-color: @primary-color;
  background-size: cover;
  background-repeat: no-repeat;

  .ant-tabs-tab {
    text-align: left;
  }
  
  @media (max-width: @screen-lg) {
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
    border-radius: @border-radius-base;

    .ant-row {
      flex-direction: column;
    }

    .ant-col {
      width: 100%;
    }
  }

  .icon-badge {
    background: @primary-color;
    color: #fff;
    position: absolute;
    left: -60px;
    top: 0;
    margin: 0;

    @media (max-width: @screen-lg) {
      position: relative;
      left: initial;
      margin: -50px 0 0;
    }
  }

  .ant-typography {
    color: #fff !important;
  }

  &__details {
    padding-right: 20px;

    @media (max-width: @screen-lg) {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      padding: 30px;
    }
  }

  &__map {
    height: 400px;
    width: 100%;
    background: #fff;

    @media (max-width: @screen-lg) {
      border-radius: 0 0 @border-radius-base @border-radius-base;
      overflow: hidden;
    }
  }
}

.home-explore {
  background: linear-gradient(90deg, #FFFFFF 0%, #F7F6FC 100%);

  &__intro {
    margin-bottom: 20px;
  }

  &__tab-description {
    font-size: 16px;
  }

  &__tab-cards {
    margin-top: 20px !important;
  }
  
  .ant-tabs-bar {
    text-align: center;
    border-bottom: none;
  }
  
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    border-radius: 4px;
    border-color: transparent;
    background: none;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    border-color: @primary-color;
    background: #FFFFFF;
  }
}