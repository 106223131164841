.footer {
  border-top: 1px solid @border-color-base;
  background-color: #fff;
  position: relative;

  @media (max-width: @screen-lg) {
    padding-left: 0;
    padding-right: 0;
  }

  &__brand {
    display: block;
    max-height: 30px;
  }

  &__logo {
    display: inline-block;
    max-width: 100%;
    max-height: 30px;
    margin-left: 10px;
    margin-right: 10px;

    &--bscs {
      max-height: 25px;
    }
    &--moore {
      max-height: 28px;
    }
    &--nsf {
      max-height: 36px;
    }
  }

  &__copyright {
    max-width: 300px;
    display: block;
  }

  &__navigation {
    margin-left: auto;
    display: flex;

    &--corporate,
    &--project {
      border: none;

      .ant-menu-item {
        padding: 0;
        line-height: 30px;
        height: 30px;
        margin: 0;

        .ant-btn-link {
          padding: 0;
          color: inherit;
        }

        @media (max-width: @screen-lg) {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  &__title {
    display: block;
    font-size: @font-size-lg;
    font-weight: @typography-title-font-weight;
    margin-bottom: @typography-title-margin-bottom;

    @media (max-width: @screen-lg) {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }

  @media (max-width: @screen-lg) {
    .ant-row {
      flex-direction: column;
      align-items: flex-start;

      .ant-col {
        margin: 0;
      }
    }

    .footer__support {
      display: block;
    }
  }
}