.ant-layout-header {

  @media (max-width: @screen-lg) {
    padding-left: 10px;
    padding-right: 10px;
  }

  .ant-affix & {
    box-shadow: 0 2px 2px rgba(black, .1);
  }
}

.navbar {

  .ant-skeleton-element {
    vertical-align: middle;
    display: inline-flex;
  }

  .ant-menu-horizontal {
    background: inherit;
    border: none;
    display: flex;
    align-items: center;

    &.ant-dropdown-trigger {
      align-self: center;
    }

    .ant-menu-item {
      border: 1px solid transparent;
      line-height: @menu-item-height;
      font-weight: 600;
      border-radius: @border-radius-base;
      margin: 0;
      padding: 0 20px;

      @media (max-width: @screen-md) {
        padding-left: 15px;
        padding-right: 15px;
      }

      svg {
        margin-right: 2px;
      }

      &-selected {
        border-color: rgba(@link-color, .6) !important;
        color: @link-color  !important;
        background: transparent !important;

        a {
          color: inherit;
        }

        &:after {
          border-bottom: 0;
        }
      }

      &:hover {
        background: rgba(@primary-color, .1);
        border-color: transparent;

        &:after {
          border-bottom: 0;
        }
      }
    }
  }

  &__Nav {
    display: flex;
  }

  &__AppNav {
    margin: auto;
  }

  &__AuthNav {
    margin-left: auto;
  }

  @media (max-width: @screen-lg) {
    &.container {
      padding: 0;
    }

    &__Nav {
      flex: none !important;
    }

    &__AppNav {
      display: none !important;
    }

    &__MobileNav {
      margin-left: auto !important;
      margin-right: 5px;
    }

    .sr-only-mobile {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px !important;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      width: 1px;
    }
  }

  @media (min-width: (@screen-lg)) {

    &__MobileNav,
    .mobile-add-data {
      display: none !important;
    }
  }

  @media (max-width: @screen-xs) {
    .ant-col {
      padding: 0;
    }

    .ant-dropdown-button .ant-btn,
    .ant-dropdown-link {
      height: @btn-height-sm;
      padding: 0px @btn-padding-horizontal-sm;
      font-size: @btn-font-size-sm;
    }
  }
}
