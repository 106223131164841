.clickable-table-cell {
    cursor: pointer;
}

.manage-table-toolbar {
    display: flex;
    flex: 1;
    max-width: 100%;
    justify-content: space-between;
}

.loc-group-name {
    width: 300px;
}

.ant-table-empty .ant-table-header .ant-table-cell:not(.ant-table-selection-column) {
    width: 10em;
}
