.download-progress {
    width: 300px;
    position: fixed;
    bottom: 16px;
    left: 40px;
    z-index: 4;
    opacity: 1;
    transition: opacity 1s 1s;
}

.fade {
    opacity: 0;
    transition: opacity 1s 1s;
}

.download-progress-list {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.download-progress-divider {
    margin: 8px;
}

.download-progress-item {
    width: 100%;
}

.ant-badge.download-progress-badge .ant-badge-count {
    background-color: #5B7102;
    margin-bottom: 5px;
}

.download-progress-error-text {
    color:#C6333A;
}

.download-progress .ant-card-head {
    background-color: #E6EAD9;
}

.download-progress .ant-card-body {
    max-height: 50vh;
    overflow: scroll;
    display: none;
    margin-bottom: 5px;
}

.download-progress-expanded .ant-card-body {
    display: block;
}

.download-length-warning {
    display: block;
    margin-top: 10px;
    padding: 10px;
    background-color: #E6EAD9;
}

.download-modal-text {
    display: block;
}

.download-modal-text-margin {
    display: block;
    margin-top: 10px;
}
