.colored-card {
  transform: rotate(-2deg);
  border-radius: @border-radius-base;
  box-shadow: 0px 2px 6px 0px @shadow-color;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;

  .ant-card {
    transform: rotate(2deg);
    display: flex;
    flex: 1;
    flex-direction: column;

    &-body {
      flex: 1;
    }

    &-actions {
      border: none;
      display: flex;
      padding: 0 @card-padding-base @card-padding-base;
      justify-content: flex-end;
  
      > li {
        flex: none;
  
        &:only-child {
          width: auto !important;
        }
  
        > span { cursor: initial; }
      }
    }

    &.media-card {
      min-height: 300px;
      background-size: cover;
      background-position: center;
    }
  }

  .ant-typography:not(.colored-card__title) {
    margin: 0 !important;
  }
}