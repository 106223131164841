.map-layersummary {
    margin-bottom: 4px;

    p {
        color: white;
        text-align: left;
        background: gray;
        padding-left: 4px;
        margin: 0;
    }

    dl {
        margin: 0;
        text-align: left;
        padding-left: 4px;
        border-left: solid 1px gray;
        border-right: solid 1px gray;
        border-bottom: solid 1px gray;
        text-align: left;
        padding-left: 4px;

        dd {
            text-align: right;
            padding-right: 4px;
        }
    }
}
