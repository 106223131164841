.visualization {
    background: @off-white;

    &__sider {
        max-height: calc(100vh - 64px);
        display: flex;
        flex-direction: column;
        min-height: 500px;
        border-right: 1px solid @border-color-base;
        z-index: 2;

        .embed & {
            max-height: 100vh;
        }

        .ant-layout-sider-children {
            height: inherit;
            min-height: 100%;
            padding: 20px;
            overflow: auto;
            flex: 1;
        }

        &.ant-layout-sider-collapsed .ant-layout-sider-children {
            padding: 0;
        }

        &.ant-layout-sider-collapsed {
            .ant-layout-sider-zero-width-trigger {
                position: fixed;
                bottom: 10px;
                left: 0;
            }
        }

        .ant-layout-sider-zero-width-trigger {
            top: initial;
            bottom: 10px;
            border-left: none;
            border: 1px solid @border-color-base;
        }

        &--edit-header {
            margin: -20px -20px 0px;
            padding: 20px;
            border-bottom: 1px solid @border-color-base;
            position: sticky;
            top: -20px;
            z-index: 2;
            background: #fff;
        }

        &--actions {
            text-align: right;
        }

        &--add-actions {
            margin: 0 -20px 20px;
            padding: 10px 20px;
            border-bottom: 1px solid @border-color-base;
            background: @off-white;
        }

        &--card {
            width: 100%;
            border: 1px solid @border-color-base;
            padding: 10px;
            border-radius: @border-radius-base;
            margin-bottom: -10px;

            &-title {
                font-size: 16px;
                display: block;
            }

            &-meta {
                display: block;
            }
        }

        &--tabs {
            margin-left: -20px;
            margin-right: -20px;
            padding: 0 20px;

            .ant-tabs-bar {
                margin: 0 -20px 20px;
                padding: 0px 20px;
                border-bottom: 1px solid @border-color-base;
                background: @off-white;
            }
        }
    }

    &__modal {
        &--body {
            display: inline-flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;
        }

        &--footer {
            width: 100%;
            align-items: center;

            .ant-col {
                display: flex;
                flex-flow: row nowrap;
                align-items: baseline;
            }

            p, img, svg {
                display: inline;
                max-height: 1.25em;
                margin-right: 4px;
            }

            &--credit {
                justify-content: flex-end;
                flex-grow: 2;
            }
        }
    }

    &__number-block {
        display: inline-flex;
        width: 30px;
        height: 30px;
        border: 1px solid @border-color-base;
        border-radius: @border-radius-base;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__embed-header {
        width: 100%;
        border-bottom: 1px solid @border-color-base;

        &.ant-layout-header {
            height: auto;
            line-height: normal;
            padding: 0 10px;
        }

        h4, p, img, svg {
            display: inline;
            max-height: 1.25em;
            margin-right: 4px;
        }

        .ant-row {
            display: flex;
            flex-flow: row nowrap;
            flex-grow: 2;
            align-items: baseline;
            width: 100%;
            padding: 4px;
        }

        .ant-col {
            display: flex;
            flex-flow: row nowrap;
            align-items: baseline;
        }

        &--title {
            margin-right: 8px;
        }

        &--more-info {
            .ant-btn {
                padding: 4px;
                color: @primary-color;
                font-weight: 400;
                border: transparent;
                border-radius: 0%;
                background-color: rgba(@primary-color, .1);
            }
        }

        &--credit {
            justify-content: flex-end;
            flex-grow: 2;
            right: 0;
        }
    }

    &__embed-footer {
        text-align: center;
        padding: 4px 0;
    }
}

.visualization-navbar {
    padding: 0 10px;
    border-bottom: 1px solid @border-color-base;

    .container {
        max-width: 100%;
    }
}

.ant-layout-sider-collapsed + .ant-layout-content .widget-container {
    max-width: 100vw;
    height: 100%;
    overflow-y: clip;

    .widget {
        height: calc(100vh - 100px);
    }
}

.widget-fullwidth-mode .widget-container--item {
    max-width: 100%;
}

.widget-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: calc(100vw - 400px);
    min-height: 500px;
    height: calc(100vh - 200px);
    max-height: 100%;
    margin-left: auto;
    scroll-padding: 0.5rem;
    overflow-y: scroll;
    flex: 100%;

    &--item {
        flex: 1;
        display: flex;
        padding: 20px;
        padding-bottom: 10px;
        width: 100%;
        height: 90%;  // this is used by setRowHeight in embed view
    }

    &--embed {
        display: flex;
        flex-flow: row wrap;
        max-width: 100vw;
        align-content: flex-start;
        scroll-padding: 0.5rem;
        height: calc(100vh - 44px); // Header is 44px tall, footer is 30px tall
        margin-left: 0;
        margin-top: 10px;
        overflow-y: scroll;

        .widget-container--item {
            padding-top: 10px;
            padding-bottom: 10px;
            flex: 100%;
            max-width: 100%;
        }

        .widget {
            height: calc(100vh - 110px);
        }
    }
}

.widget {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 350px;
    height: calc(100vh - 200px);
    max-height: 100%;
    max-width: 100%;
    border-radius: @border-radius-base;
    scroll-snap-align: start;

    &-toolbar {
        padding-left: 15px;
        padding-right: 15px;

        .widget-container--item & {
            padding-left: 20px;
            padding-right: 20px;
        }

        .visualization &__section {
            &.left {
                // starts off at a quarter of the bar (25% flex basis)
                // but can grow to be 100% of it (4x)
                flex: 4 1 25%;
            }

            &.center {
                flex: 0 1 50%;
            }

            &.right {
                flex: 0 1 25%;
            }
        }

    }

    &-toolbar + &-body {
        border-radius: 0 0 @border-radius-base @border-radius-base;
        border-top: none;
    }

    &-body {
        flex: 1;
        border: 1px solid @border-color-base;
        background: #fff;
        border-radius: @border-radius-base;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &__padder {
            padding: 20px;
        }

        &__loader {
            display: flex;
            min-height: 100px;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }

    .observation-map {
        height: 100%;
        min-height: 350px;

        /* browser fixes */
        > div {
            height: 100% !important;
            min-height: 350px;

            > div {
                min-height: 350px;
            }
        }
        /* end browser fixes */
    }

    .observation-data {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow: auto;

        .widget-toolbar {
            flex: none;
        }

        .widget-toolbar__section.center {
            display: initial;
        }

        .ant-table-pagination.ant-pagination {
            padding: 10px;
            margin: 0;
            background: white;
        }
    }

    .ant-statistic {
        display: inline-flex;
        flex-direction: column-reverse;
    }

    .ant-statistic-content {
        line-height: 1.2;
    }

    .ant-statistic-title {
        margin: 0;
    }
}

.observation-data {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .widget-toolbar {
        flex: none;

        @media (max-width: (830px)) {
            .left .desktop {
                display: none !important;

            }
          }
        @media (min-width: (830px)) {
            .left .mobile {
                display: none !important;
            }
          }

        @media (max-width: (500px)) {
            .right .desktop {
                display: none !important;
            }
          }
    }

    .widget-toolbar__section.center {
        display: initial;
    }

    .ant-table-pagination.ant-pagination {
        padding: 10px;
        margin: 0;
        background: white;
    }
}

.ant-dropdown-menu {
    display: flex;
    flex-direction: column;

    .ant-btn {
        color: @primary-color;
        border: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
    }
}

.chart-container {
    padding: 10px;
    height: 100%;
    max-height: 100%;
    min-height: 300px;
    width: 100%;
    position: relative;
    z-index: 1;

    /* browser fixes */
    > div {
        min-height: 280px;
    }
    /* end browser fixes */

    .uplot {
        max-width: 100%;

        .u-legend.u-inline {
            user-select: none;
            pointer-events: none;
            display: flex;
            font-size: 13px;
            margin-top: -5px;
            margin-left: -10px;
            margin-right: -10px;
            justify-content: space-evenly;

            .u-series {
                display: inline-flex;
                margin-right: 0;
                align-items: flex-end;
                flex-direction: column;

                > * {
                    padding: 0;
                }
            }
        }
    }
}

.filter-set-section {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;

        &--description {
            flex: 1;
            margin-left: 10px;
        }

        h5 {
            margin-bottom: 0;
        }
    }

    &__body {
        padding-left: 40px;

        .ant-list-item {
            align-items: baseline;
            justify-content: flex-start;
            padding: 2px 0;

            p {
                margin-bottom: 0;
                margin-left: 1em;
            }
        }
    }
}

.filter-set-filter {
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;

    &__content {
        flex: 1;

        .ant-typography {
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: 600;
        display: block;
        cursor: pointer;
    }

    &__actions {
        margin-left: auto;

        .ant-btn {
            border: none;
            box-shadow: none;
        }
    }

    &__editor {
        width: 100%;
        margin-top: 5px;
    }
}

.add-filter-actions {
    font-weight: 600;
}

.recharts-tooltip-wrapper {
    max-width: 80%;

    .recharts-default-tooltip {
        white-space: normal !important;
    }
}

.recharts-tooltip-table {
    thead tr td {
        padding: 0.2rem 0.5rem;
    }
    tbody tr td {
        padding: 0.2rem 0.5rem;
    }
    tbody tr td.value {
        text-align: right;
    }
}

.rdw-link-modal {
    height: auto;
}

// Remove irrelevant section from non-customizable 3rd party form
// (react-draft-wysiwyg)
.rdw-link-modal-target-option {
    display: none;
}

nav {
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 3;
    position: fixed;
    bottom: 1px;
    width: 100%;

    .rowButton {
        color: #666;
        width: 3rem;
        display: inline-block;
        border-radius: 2px;
        border: 1px solid transparent;
        text-align: center;
        background-color: #f5f5f5;

        &:hover {
            background: @off-white;
        }

        &.active {
            border-color: #E0E0E0;
            pointer-events: none;
            width: 3rem;
        }
    }
}