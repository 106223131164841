.section-divider {
  background: @primary-color;
  opacity: .5;

  &.ant-divider-horizontal {
    height: 2px;
    max-width: 150px;
    width: 100%;
    min-width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}