@import 'theme.less';

.offline-top-banner {
    background-color: @offline-highlight-color;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    &__message {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.offline-interior-banner {
    background-color: @offline-highlight-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;

    &__button {
        padding-left: 10%;
    }
}

.offline__button {
  background-color: transparent;
  border-color: black;
  color: black;
  padding: 0 5px;
  margin: 0 15px;

  &:active {
      background: transparent;
  }
  &:focus {
      background: transparent;
      border-color: black;
      color: black;
  }
  &:hover {
      background: rgba(black, .1);
      border-color: black;
      color: black;
  }
}
