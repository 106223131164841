.icon-badge {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  background: rgba(@primary-color, .1);
  border-radius: 200px;
  font-size: 20px;
  color: @primary-color;
}