.form-card {
  box-shadow: 0px 2px 10px -4px @shadow-color;
  position: relative;
  z-index: 1;

  &__title {
    display: inline-flex;
    align-items: center;
  }
}

.auth-form {
  max-width: initial;

  &__buttongroup {

    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }

    button[type="submit"] {
      margin-right: auto;
    }
  }
}

.ant-form-vertical {

  .ant-form-item-label {
    padding: 0;
  }

  .ant-radio-group {
    display: flex;
    flex-direction: column;

    .ant-radio-wrapper {
      margin-bottom: 5px;
    }
  }

  .ant-picker,
  .ant-input-number {
    display: block;

    &-input.custom-number-input {
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      width: 100%;
    }

    &-input.custom-number-input:hover {
      border: 1px solid rgba(@primary-1, 1);
    }
  }
}

.ant-space.custom-number-input {
  width: 100%;

  .ant-space-item {
    width: 45%;
  }
}

.form-field {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-unit {
    padding-left: 5px;
  }
}
