.observation {
  .form-card__title {
    margin-bottom: 7px;

    &-text {
      margin-left: 8px;
      margin-bottom: 3px !important;
    }
  }

  &__section {
    margin: 15px -25px;
    padding: 10px 25px;
    border-top: 2px solid #f0f0f0;

    &--header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &--collapse {
      border: none;
      box-shadow: none;
      height: 100%;
      white-space: normal;
      text-align: left;
      display: inherit;

      svg {
        margin-top: 5px;
      }
    }

    &--required {
      color: red;
    }

    &--title {
      font-size: 16px;
      font-weight: 500;
    }

    .ant-form-item:last-of-type {
      margin-bottom: 0;
    }

    &--children.hidden {
        display: none;
    }

    &--editable {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .station-map {

    @media (min-width: @normalize-widths) {
      margin-left: -50px;
      margin-right: -50px;
    }
  }

  &__observed {
    padding: 15px;
    margin-bottom: 10px;
    background-color: @off-white;
    border: 1px solid #f0f0f0;
    border-radius: @border-radius-base;
    position: relative;

    &:not(:first-of-type):after {
      content: '';
      position: absolute;
      right: -15px;
      height: 100%;
      width: 14px;
      background: #ffffff;
      top: -50%;
      border: 2px solid #f0f0f0;
      border-left: none;
      border-radius: 0 4px 4px 0;
    }
  }

  &__newcomment {
      margin: 0 0 20px 0;
  }
}

.observation-table {
  border: 1px solid @border-color-base;
  border-radius: 0 0 @border-radius-base @border-radius-base;

  .ant-table {
    border-radius: 0;
    white-space: pre;

    .ant-table-container {
      border: none;

      .ant-table-row-selected:not(:hover) {
        color: @table-selected-row-color;

        .ant-table-cell {
          border-color: @table-selected-row-bg;
        }
      }

      .ant-table-cell-ellipsis {
        max-width: 200px;
      }
    }
  }
}

.observation-drawer {
  @media(min-width: 400px) {
    .ant-drawer-content-wrapper {width: 400px !important;}
  }

  @media(max-width: 399px) {
    .ant-drawer-content-wrapper {width: 100vw !important;}
  }

  &__title {
    color: @primary-color;
    padding-top: 10px;
  }

  &__map {
    border-radius: @border-radius-base;
    overflow: hidden;
    margin-bottom: 10px;
    padding-top: 10px;
  }

  .toggle-station-details {
    color: @primary-color;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    font-size: small;
    padding-left: 0px;
    padding-bottom: 20px;
    display: inline-flex;
    align-items: center;

    .station-details-caret {
      font-size: x-small;
      padding-right: 4px;
    }
  }
  
  &__detail, &__media {
    padding: 0;

    &--item {
      list-style-type: none;
      margin-top: 14px;
    }

    .label {
      font-size: 12px;
      margin: 0;
    }

    .value {
      margin-top: -2px;
      font-size: 16px;
    }

    .ant-typography {
      display: block;
    }
  }
}

.observation-form {

  &__footer {
    padding: 15px 0px;
    text-align: center;

    .ant-affix & {
      background-color: rgba(255, 255, 255, 0.75);
      border: 1px solid #f0f0f0;
      margin: 0 -25px 0;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.observation-modal__media {

  .ant-card {
    overflow: hidden;

    &-head {
      padding-left: 15px;
      padding-right: 15px;
    }

    &-body {
      padding: 0;
    }

    &-meta {
      padding: 15px;

      .ant-typography {
        margin-bottom: 0;
      }
    }

    &-cover {
      height: 200px;
      display: flex;
      align-items: center;
      overflow: hidden;
      background: #f0f0f0;

      img {
        border-radius: 0;
        align-self: center;
        flex: 1 0 auto;
        display: inline-flex;
        object-fit: contain;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}
